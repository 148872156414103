<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("containers", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="addResourceTypeForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("containers", pageName, "name") }}
              </label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="displayLabelName('containers', pageName, 'name')"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import rolesMixin from "@/services/mixins/roles/rolex-mixin";
export default {
  name: "AddRole",
  computed: {
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "add-role-user";
      } else if (containerType === "RESOURCE") {
        return "add-role-resource";
      } else if (containerType === "LEVEL") {
        return "add-role-level";
      } else if (containerType === "USER_MANAGER") {
        return "add-role-user-manager";
      } else if (containerType === "RECEPTION_DESK") {
        return "add-reception-desk-role";
      }
      return "";
    }
  },
  methods: {
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      values.access_type_id = this.containerTypeId;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.company.roles, values)
        .then(() => {
          this.fetchRoles();
          this.$refs.addResourceTypeForm.setValues({
            name: ""
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [rolesMixin]
};
</script>
